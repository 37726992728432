import { VsIcon, genVsIconBaseProps } from '../shared';

export const ImportIcon: VsIcon = props => {
  return (
    <svg {...genVsIconBaseProps(props)} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.38965 4.11727C8.24203 4.03865 8.07705 3.99832 7.9098 3.99996L7.9 4.00005V4H4C3.73478 4 3.48043 4.10536 3.29289 4.2929C3.10536 4.48043 3 4.73479 3 5V9C3 9.55229 2.55228 10 2 10C1.44772 10 1 9.55229 1 9V5C1 4.20436 1.31607 3.44129 1.87868 2.87868C2.44129 2.31607 3.20435 2 4 2H7.89559C8.39547 1.996 8.88846 2.11698 9.32975 2.35198C9.77076 2.58684 10.1461 2.92812 10.4217 3.3448L11.2288 4.54053L11.2352 4.54997L11.2352 4.55C11.3262 4.68827 11.4502 4.80176 11.5959 4.88031C11.7416 4.95882 11.9045 4.99995 12.07 5H12.0702H20C20.7957 5 21.5587 5.31608 22.1213 5.87868C22.6839 6.44129 23 7.20435 23 8V18C23 18.7957 22.6839 19.5587 22.1213 20.1213C21.5587 20.6839 20.7957 21 20 21H4C3.20435 21 2.44129 20.6839 1.87868 20.1213C1.31607 19.5587 1 18.7957 1 18V17C1 16.4477 1.44772 16 2 16C2.55228 16 3 16.4477 3 17V18C3 18.2652 3.10536 18.5196 3.29289 18.7071C3.48043 18.8946 3.73478 19 4 19H20C20.2652 19 20.5196 18.8946 20.7071 18.7071C20.8946 18.5196 21 18.2652 21 18V8C21 7.73479 20.8946 7.48043 20.7071 7.2929C20.5196 7.10536 20.2652 7 20 7H12.07L12.0698 7C11.5732 6.99992 11.0843 6.87653 10.6471 6.64091C10.2117 6.40628 9.84114 6.06767 9.5683 5.65526L8.76115 4.45948L8.75475 4.44999L8.7548 4.44996C8.66282 4.31027 8.53728 4.19588 8.38965 4.11727ZM1 13C1 12.4477 1.44772 12 2 12H9.58579L8.29289 10.7071C7.90237 10.3166 7.90237 9.68342 8.29289 9.29289C8.68342 8.90237 9.31658 8.90237 9.70711 9.29289L12.7064 12.2922C12.7088 12.2946 12.7112 12.297 12.7136 12.2995C12.8063 12.3938 12.8764 12.502 12.9241 12.6172C12.9727 12.7343 12.9996 12.8625 13 12.997C13 12.998 13 12.999 13 13C13 13.001 13 13.002 13 13.003C12.9992 13.2746 12.8901 13.5208 12.7136 13.7005C12.7112 13.703 12.7088 13.7054 12.7064 13.7078L9.70711 16.7071C9.31658 17.0976 8.68342 17.0976 8.29289 16.7071C7.90237 16.3166 7.90237 15.6834 8.29289 15.2929L9.58579 14H2C1.44772 14 1 13.5523 1 13Z"
      />
    </svg>
  );
};
