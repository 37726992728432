import { Mutex, MutexInterface } from 'async-mutex';

let _tokenRefreshMutexReleasor: MutexInterface.Releaser | undefined;

export const tokenRefreshMutex = new Mutex();

export async function lockForTokenRefresh() {
  if (!_tokenRefreshMutexReleasor) {
    _tokenRefreshMutexReleasor = await tokenRefreshMutex.acquire();
  }
}

export function releaseLockForTokenRefresh() {
  if (_tokenRefreshMutexReleasor) {
    _tokenRefreshMutexReleasor();
    _tokenRefreshMutexReleasor = undefined;
  }
}
