import { isErrorResponseOfVsApi } from '@vs/rtk/utils';

import {
  AccountGetEntityInfoApiResponse,
  Method as MFAMethod,
  UpdateAccountDto,
  UserMfaGetApiResponse,
  UserMfaListResponseDto,
  useAccountFindDetailQuery,
  vsAccount2ApiInjected,
} from './vsAccount2ApiInjected';

export enum VsAccountSliceTags {
  ACCOUNT_ENTITY_INFO = 'ACCOUNT_ENTITY_INFO',
  ACCOUNT_SETTINGS = 'ACCOUNT_SETTINGS',
  AVATAR = 'AVATAR',
  MARKETING = 'MARKETING',
  GET_MFA_LIST = 'GET_MFA_LIST',
  USER_SSO_LIST = 'USER_SSO_LIST',
  ACCOUNT_DETAIL = 'ACCOUNT_DETAIL',
}

const MFA_LIST = [
  MFAMethod.Authenticator,
  MFAMethod.Email,
  // MFAMethod.Phone,
];

const vsAccount2ApiEnhanced = vsAccount2ApiInjected.enhanceEndpoints({
  addTagTypes: Object.values(VsAccountSliceTags),
  endpoints: {
    accountGetEntityInfo: {
      providesTags: [VsAccountSliceTags.ACCOUNT_ENTITY_INFO],
      extraOptions: {
        fallbackResponseOfNotFound: [
          {
            data: {
              id: '',
              name: '',
              generics_code: '',
              domain_type_code: '',
              area: '',
              create_time: '',
              modify_time: '',
              profile: {
                contact_email: '',
                contact_phone: '',
                zipcode: '',
                country_code: '',
                country: '',
                region: '',
                city: '',
                address: '',
              },
              domains: [],
              entities: [],
            },
          },
        ] as AccountGetEntityInfoApiResponse,
        checkIsNotFoundError: error => {
          if (
            isErrorResponseOfVsApi(error) &&
            error.data?.status_code === 400 &&
            typeof error.data?.message === 'string' &&
            error.data?.message.includes('Cannot find user entity info')
          ) {
            return true;
          }
          return false;
        },
      },
    },
    accountUpdate: {
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        await queryFulfilled;
        const argsOfQuery = vsAccount2ApiEnhanced.util.selectCachedArgsForQuery(
          getState(),
          'accountFind'
        );
        dispatch(
          vsAccount2ApiEnhanced.util.updateQueryData(
            'accountFind',
            argsOfQuery[0],
            draft => {
              const updatePayload =
                arg.updateAccountDto as Required<UpdateAccountDto>;
              Object.assign(draft, updatePayload);
            }
          )
        );
      },
    },
    userAvatarGet: {
      providesTags: [VsAccountSliceTags.AVATAR],
    },
    userAvatarUpdate: {
      invalidatesTags: [VsAccountSliceTags.AVATAR],
    },
    userAvatarDelete: {
      invalidatesTags: [VsAccountSliceTags.AVATAR],
    },
    accountMarketingUpdate: {
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        await queryFulfilled;
        const argsOfQuery = vsAccount2ApiEnhanced.util.selectCachedArgsForQuery(
          getState(),
          'accountMarketingGet'
        );
        dispatch(
          vsAccount2ApiEnhanced.util.updateQueryData(
            'accountMarketingGet',
            argsOfQuery[0],
            draft => {
              draft.email_campaign =
                arg.updateUserMarketingCampaignDto.email_campaign;
            }
          )
        );
      },
    },
    accountVerifyEmail: {
      extraOptions: { isPublicEndpoint: true },
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        await queryFulfilled;
        const argsOfQuery = vsAccount2ApiEnhanced.util.selectCachedArgsForQuery(
          getState(),
          'accountFind'
        );
        dispatch(
          vsAccount2ApiEnhanced.util.updateQueryData(
            'accountFind',
            argsOfQuery[0],
            draft => {
              draft.email_is_verified = true;
            }
          )
        );
      },
    },
    accountDeleteUser: {
      extraOptions: { isPublicEndpoint: true },
    },
    userMfaGet: {
      providesTags: [VsAccountSliceTags.GET_MFA_LIST],
      transformResponse: (response: UserMfaGetApiResponse) => {
        const mfaList = MFA_LIST.map(method =>
          response.find(item => item.method === method)
        );
        const availableMfaList = mfaList.filter(
          item => item !== undefined
        ) as UserMfaListResponseDto[];
        return availableMfaList;
      },
    },
    userMfaVerifyOtp: {
      invalidatesTags: [VsAccountSliceTags.GET_MFA_LIST],
    },
    userMfaDelete: {
      invalidatesTags: [VsAccountSliceTags.GET_MFA_LIST],
    },
    userMfaSetDefault: {
      invalidatesTags: [VsAccountSliceTags.GET_MFA_LIST],
      async onQueryStarted(
        { accountId, setMfaAsDefaultDto: { mfaCode } },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          vsAccount2ApiInjected.util.updateQueryData(
            'userMfaGet',
            { accountId },
            draft => {
              draft.forEach(item => {
                item.isDefault =
                  item.method === (mfaCode as unknown as MFAMethod);
              });
              return draft;
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    },
    userSsoGet: {
      providesTags: [VsAccountSliceTags.USER_SSO_LIST],
    },
    userSsoDelete: {
      invalidatesTags: [VsAccountSliceTags.USER_SSO_LIST],
    },
    userSsoUpdate: {
      invalidatesTags: [VsAccountSliceTags.USER_SSO_LIST],
    },
    accountFindDetail: {
      providesTags: [VsAccountSliceTags.ACCOUNT_DETAIL],
    },
    managementAccountUpdateStatus: {
      invalidatesTags: [VsAccountSliceTags.ACCOUNT_DETAIL],
    },
  },
});

export * from './vsAccount2ApiInjected';
export { vsAccount2ApiEnhanced as vsAccount2ApiSlice };

type supportedSize = { width: 150; height: 150 } | { width: 48; height: 48 };
export const useAvatarUrl = (
  size: supportedSize,
  accountId: string
): string => {
  const { data: avatar } = vsAccount2ApiEnhanced.useUserAvatarGetQuery(
    {
      accountId,
    },
    { skip: !accountId }
  );

  // example avatar_url ==> https://assets.dev.viewsonic.cloud/avatar/438f9ff3-ed9a-4332-b30c-7da1a24c5582_48x48.jpeg
  if (avatar?.avatar_url) {
    const path = avatar?.avatar_url.startsWith('/')
      ? avatar?.avatar_url
      : `/${avatar?.avatar_url}`;
    return path.replace('_48x48', `_${size.width}x${size.height}`);
  }
  return '';
};
