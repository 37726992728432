import { SigninRedirectArgs } from 'oidc-client-ts';
import { useCallback, useEffect, useRef } from 'react';
import { useAuth } from 'react-oidc-context';

import { getIsSessionEndInSameClient } from '../_private';
import { lockForTokenRefresh, releaseLockForTokenRefresh } from '../utils';

export type ArgsUseAutoSilentRefreshToken = {
  signinRedirectArgs?: SigninRedirectArgs;
  onTokenRefreshError?: (error: unknown) => void;
  onTokenRefreshStart?: () => void;
  onTokenRefreshEnd?: () => void;
  skip?: boolean;
};

export function useAutoSilentRefreshToken({
  onTokenRefreshStart = () => {},
  onTokenRefreshEnd = () => {},
  onTokenRefreshError = () => {},
  signinRedirectArgs,
  skip = false,
}: ArgsUseAutoSilentRefreshToken = {}) {
  const stateRef = useRef<{
    signinRedirectArgs: ArgsUseAutoSilentRefreshToken['signinRedirectArgs'];
  }>({ signinRedirectArgs });
  stateRef.current.signinRedirectArgs = signinRedirectArgs;
  const cbRef = useRef<
    Required<
      Pick<
        ArgsUseAutoSilentRefreshToken,
        'onTokenRefreshError' | 'onTokenRefreshEnd' | 'onTokenRefreshStart'
      >
    >
  >({
    onTokenRefreshError: () => {},
    onTokenRefreshEnd: () => {},
    onTokenRefreshStart: () => {},
  });
  cbRef.current.onTokenRefreshError = onTokenRefreshError;
  cbRef.current.onTokenRefreshStart = onTokenRefreshStart;
  cbRef.current.onTokenRefreshEnd = onTokenRefreshEnd;

  const { isAuthenticated, events, signinSilent, settings } = useAuth();

  const silentSignin = useCallback(async () => {
    try {
      cbRef.current.onTokenRefreshStart();
      await lockForTokenRefresh();
      const user = await signinSilent(stateRef.current.signinRedirectArgs);
      if (!user) {
        throw new Error('User not found.');
      } else {
        await releaseLockForTokenRefresh();
      }
    } catch (error) {
      cbRef.current.onTokenRefreshError(error);
    } finally {
      cbRef.current.onTokenRefreshEnd();
    }
  }, [signinSilent]);

  useEffect(() => {
    if (!skip && isAuthenticated) {
      let isTokenExpiredWhileDocNotViewing = false;

      const onVisibilityChange = async () => {
        if (document.visibilityState === 'visible') {
          if (
            !getIsSessionEndInSameClient() &&
            isTokenExpiredWhileDocNotViewing
          ) {
            await silentSignin();
            isTokenExpiredWhileDocNotViewing = false;
          }
        }
      };

      window.addEventListener('visibilitychange', onVisibilityChange);

      const removeAccessTokenExpiringListener = events.addAccessTokenExpiring(
        () => {
          if (document.visibilityState === 'hidden') {
            isTokenExpiredWhileDocNotViewing = true;
          } else {
            silentSignin();
          }
        }
      );

      return () => {
        window.removeEventListener('visibilitychange', onVisibilityChange);
        removeAccessTokenExpiringListener();
      };
    }
  }, [
    silentSignin,
    skip,
    isAuthenticated,
    events,
    settings,
    onTokenRefreshError,
  ]);
}
