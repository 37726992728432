import { tabSessionUUID } from '@vs/utils/constants';
import { SignoutRedirectArgs } from 'oidc-client-ts';
import { useCallback } from 'react';
import { useAuth } from 'react-oidc-context';

import { useOidcConfig } from '../hooks';
import { getOidcStorageInfo } from '../oidc-client';
import { broadcastSameClientLogout } from './useHandleSameClientLogout';

export type OidcRedirectState = {
  refererPath?: string;
  vs_client_name?: 'entity' | 'account';
};

export function useSignOut() {
  const { signoutRedirect } = useAuth();
  const { client_id, authority } = useOidcConfig();

  return useCallback(
    async (args?: SignoutRedirectArgs, sendMessage = true) => {
      const storageInfo = await getOidcStorageInfo({
        client_id,
        authority,
      });
      if (sendMessage) {
        broadcastSameClientLogout({
          client_id,
          authority,
          sessionUUID: tabSessionUUID,
          isLogout: true,
        });
      }
      await signoutRedirect({
        id_token_hint: storageInfo?.id_token,
        state: {
          refererPath: `${window.location.pathname}${window.location.search}${window.location.hash}`,
          ...((args?.state as object) ?? {}),
        } as OidcRedirectState,
        ...args,
      });
    },
    [client_id, authority, signoutRedirect]
  );
}
