'use client';

import CssBaseline from '@mui/material/CssBaseline';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { type Theme, ThemeProvider, createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';

const vsTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paperWidthSm: {
          maxWidth: 366,
        },
        paperWidthMd: {
          maxWidth: 500,
        },
        paperWidthLg: {
          maxWidth: 720,
        },
      },
    },
  },
});

export type PropsProviderMuiTheme = {
  /** set MUI style prepend before other stylesheets, should only use in client side */
  injectFirst?: boolean;
  children?: React.ReactNode;
  /**
   * Custom theme based on Viewsonic portal design guidline,
   * see [Figma](https://www.figma.com/design/aM7fAajczHiW3OI7arsuQ7/%E2%9D%96-Design-Guideline_ViewSonic-Portal?node-id=3-4&t=w1juSvoBvGe1P63w-0)
   */
  theme?: Theme;
};
/**
 * Wrapper with Mui style related provider
 *
 * @export
 * @param {PropsProviderMuiTheme} props
 * @return {JSX.Element}
 */
export function ProviderMuiTheme(props: PropsProviderMuiTheme) {
  const { children = null, theme, injectFirst = true } = props;

  const mergedTheme = theme ? createTheme(deepmerge(vsTheme, theme)) : vsTheme;

  const withTheme = (
    <ThemeProvider theme={mergedTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
  return injectFirst ? (
    <StyledEngineProvider injectFirst>{withTheme}</StyledEngineProvider>
  ) : (
    withTheme
  );
}
