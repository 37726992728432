import { AuthRoutes } from '@vs/oidc-client';
import { showToast } from '@vs/rtk/slices/genericGlobal';
import { useAuthOmitPaths } from '@vs/shared-context/spa-path-config-provider';
import { PortalBackdrop } from '@vs/ui/portal/backdrop';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../redux/hooks';
import AppContentInit from '../AppContentInit';

export default function AppAuthRoutes() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const authOmitPaths = useAuthOmitPaths();

  function showReloadToast() {
    dispatch(
      showToast({
        message: t('shared:system.toast.session_expired'),
        actionType: 'reload',
      })
    );
  }

  return (
    <AuthRoutes
      renderLoadingUI={() => <PortalBackdrop type="app_init" open />}
      renderComponent={() => <AppContentInit />}
      propsAuthGuard={{
        generateSignInState: () => ({ vs_client_name: 'account' }),
        authOmitPaths,
        onTokenRefreshError: showReloadToast,
        onLogout: showReloadToast,
      }}
    />
  );
}
