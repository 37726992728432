import { vsAuthApiBase as api } from './vsAuthApiBase';

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    loginTargetByQrCode: build.mutation<void, ArgsloginTargetByQrCode>({
      query: ({ account_id, interaction_id }) => ({
        method: 'POST',
        url: '/auth/v1/api/account/qrcode',
        body: {
          account_id,
          interaction_id,
        },
      }),
    }),
    simulate: build.mutation<RespSimulate, ArgsSimulate>({
      query: ({ id, access_token }) => {
        const formBody = new URLSearchParams();
        formBody.append('access_token', access_token);
        return {
          method: 'POST',
          url: `/auth/api/v1/management/account/${id}/simulate`,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: formBody,
        };
      },
      extraOptions: {
        isPublicEndpoint: true,
      },
    }),
    simulateWithFetch: build.mutation<RespSimulate, ArgsSimulate>({
      queryFn: async ({ id, access_token }) => {
        const formBody = new URLSearchParams();
        formBody.append('access_token', access_token);
        try {
          const response = await fetch(
            `https://dev.cloud.viewsonic.com/auth/api/v1/management/account/${id}/simulate`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: formBody,
            }
          );

          const { landing_url } = await response.json();
          return { data: { landing_url } };
        } catch (error) {
          return { data: { landing_url: '123' } };
        }
      },
      extraOptions: {
        isPublicEndpoint: true,
      },
    }),
  }),
});

export { injectedRtkApi as vsAuthApiInjected };
export type ArgsResetPassword = {
  account: string;
  referer_uri: string;
};

export interface RespChangePassword {
  nativeCode: string;
  status: number;
  responseCode: number;
  messageCode: number;
  message: string;
}

export type ArgsloginTargetByQrCode = {
  account_id: string;
  interaction_id: string;
};

export type RespSimulate = {
  landing_url: string;
};
export type ArgsSimulate = {
  id: string;
  access_token: string;
};
export const {
  useLoginTargetByQrCodeMutation,
  useSimulateMutation,
  useSimulateWithFetchMutation,
} = injectedRtkApi;
